import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslocoModule } from '@jsverse/transloco';
import { SvgSpinnerComponent } from '../svg-spinner/svg-spinner.component';
import { Store } from "@ngrx/store";
import * as CoreSelectors from '../../../core/store/core.selectors';

@UntilDestroy()
@Component({
  selector: 'ta-loader',
  template: `
    <ng-container *transloco="let translate">
      <div class="tw-block tw-h-full tw-relative">
        @if (this.show()) {
          <div class="full-width-height tw-flex tw-flex-col tw-items-center tw-justify-center tw-z-1055 tw-bg-white tw-absolute">
            <ta-svg-spinner [width]="30"></ta-svg-spinner>
            <div class="tw-pt-2 tw-text-primary">{{ translate('loading') }}...
            </div>
          </div>
        }
        <ng-content></ng-content>
      </div>
    </ng-container>
  `,
  imports: [TranslocoModule, SvgSpinnerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  public show = inject(Store).selectSignal(CoreSelectors.isLoading);

}
