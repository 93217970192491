import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { provideRouter, withComponentInputBinding, withPreloading, withRouterConfig } from '@angular/router';
import { AppRoutes } from '../app-routing';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import TranslocoConfig from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { provideEffects } from '@ngrx/effects';
import { environment as Config } from '../../environments/environment';
import 'hammerjs';
import { DatePipe } from '@angular/common';
import { HammerModule, Title } from '@angular/platform-browser';
import { RxjsUtils } from '../shared/utility/rxjs-utils';
import { LoggerModule } from 'ngx-logger';
import { DisableBackButtonModule } from './disable-back-button.module';
import { CustomPreloadingStrategy } from './router/custom-preloading-strategy';
import { provideCustomReuseStrategy, provideHammer } from './core.providers';
import { StoreEffects, StoreReducers } from "./store/store-config";
import { BaseHttpInterceptor } from './interceptors/inteceptors';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from "ngx-webstorage";
import { provideServiceWorker } from '@angular/service-worker';
import { provideSyncStore } from "@ngrx-addons/sync-state";
import { providePrimeNG } from "primeng/config";
import { PrimengTagTheme } from "../../assets/scss/primeng/tag-theme";
import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';
import { TuiLanguageName, tuiLanguageSwitcher } from '@taiga-ui/i18n';
import * as GlobalLanguage from "./language/language-global";
import * as KeycloakSettings from "./sso/keycloak-settings";
import {
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  includeBearerTokenInterceptor,
  provideKeycloak
} from "keycloak-angular";
import { ConfirmationService } from "primeng/api";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideTanStackQuery, QueryClient, withDevtools } from '@tanstack/angular-query-experimental';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';

GlobalLanguage.setLanguage('it');
const keycloakConfig = await KeycloakSettings.getSettingsAsync();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

// Sync delle query su più schede
broadcastQueryClient({ queryClient, broadcastChannel: 'query-broadcast-ch' });

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        BaseHttpInterceptor,
        includeBearerTokenInterceptor
      ])
    ),
    provideTransloco(TranslocoConfig),
    provideStore(StoreReducers),
    provideSyncStore<typeof StoreReducers>({
      states: [
        { key: 'role' },
        { key: 'operators' },
        { key: 'dashboard' },
        { key: 'licenses' },
        { key: 'settings-core' }
      ]
    }),
    provideEffects(StoreEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: isDevMode(),
      traceLimit: 25,
      serialize: {
        options: {
          map: true
        }
      }
    }),
    provideRouterStore(),
    DatePipe,
    importProvidersFrom(
      Title,
      RxjsUtils,
      LoggerModule.forRoot({
        timestampFormat: 'dd/MM/YYYY HH:mm:ss',
        disableFileDetails: !isDevMode(),
        enableSourceMaps: isDevMode(),
        level: Config.loggerLevel
      }),
      DisableBackButtonModule,
      HammerModule,
      RxReactiveFormsModule
    ),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: '', separator: '', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
    provideRouter(
      AppRoutes,
      withPreloading(CustomPreloadingStrategy),
      withComponentInputBinding(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    provideCustomReuseStrategy(),
    provideKeycloak({
      config: keycloakConfig,
      initOptions: {
        pkceMethod: 'S256',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}${Config.baseHref}/assets/silent-check-sso.html`,
        silentCheckSsoFallback: false
      }
    }),
    {
      provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
      useValue: [{ urlPattern: /^.*$/ }]
    },
    provideHammer(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    providePrimeNG({
      ripple: false,
      theme: {
        preset: PrimengTagTheme,
        options: {
          darkModeSelector: '.dark',
          cssLayer: {
            name: 'primeng',
            order: 'tailwind-base, primeng, tailwind-utilities'
          }
        }
      }
    }),
    NG_EVENT_PLUGINS,
    tuiLanguageSwitcher(
      async (language: TuiLanguageName): Promise<unknown> => {
        switch (language) {
          case 'italian':
            return import('@taiga-ui/i18n/languages/italian');
          case 'spanish':
            return import('@taiga-ui/i18n/languages/spanish');
          default:
            return import('@taiga-ui/i18n/languages/english');
        }
      }
    ),
    ConfirmationService,
    provideTanStackQuery(
      queryClient,
      withDevtools(() => ({
        loadDevtools: sessionStorage.getItem('ts_dev') === 'true' || Config.branch === 'alpha'
      }))
    )
  ]
};
