import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';
import * as OperatorSelectors from '../operators/store/operators.selector';
import { filterNotNull } from '../../shared/utility/rxjs-utils';
import { RoleService } from '../role/services/role.service';
import Keycloak from "keycloak-js";
import { OperatorDTOExtended } from "../operators/operators.model";
import { environment as Config } from "../../../environments/environment";

export const loggedOperatorGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);
  return store.select(OperatorSelectors.loggedOperator).pipe(
    filterNotNull,
    take(1),
    map(() => true)
  );
};

export const loggedOperatorHasPasswordResetGuard: CanActivateFn = (): boolean | Observable<boolean | UrlTree> => {
  const router = inject(Router);
  if(!inject(Keycloak).authenticated || router.getCurrentNavigation()?.extras.state?.['isLoginError']) {
    return true;
  }

  const store = inject(Store);
  const roleService = inject(RoleService);
  return store.select(OperatorSelectors.loggedOperator).pipe(
    filterNotNull,
    take(1),
    map(operator => {
      if(!operator.passwordReset) {
        return buildUserHome(operator, router, roleService);
      }
      return true;
    })
  );
};

export const redirectUserHomeGuard: CanMatchFn = (): Observable<UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);
  const roleService = inject(RoleService);
  return store.select(OperatorSelectors.loggedOperator).pipe(
    filterNotNull,
    take(1),
    map(operator => buildUserHome(operator, router, roleService))
  );
};

export const logoutGuard: CanMatchFn = (): boolean => {
  const keycloak = inject(Keycloak);
  const redirectUri = `${location.origin}${Config.baseHref}`;
  if(keycloak.idToken) {
    keycloak.logout({ redirectUri });
  } else {
    location.href = redirectUri;
  }
  return false;
};

const buildUserHome = (operator: OperatorDTOExtended, router: Router, roleService: RoleService) => {
  const params = router.getCurrentNavigation()?.initialUrl.queryParams;
  let result: string[];
  const homePage = operator.operatorSettings?.otherSettings?.HOME_PAGE_FWO_FUNCTIONALITY;
  if(homePage){
    result = [roleService.getWebOperationPathById(homePage, operator)];
  }else{
    result = ['my-profile'];
  }
  return router.createUrlTree(result, { queryParams: params });
};
