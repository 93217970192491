import { Injectable, signal } from '@angular/core';
import { fromEvent } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NetworkService {

  private readonly _isOffline = signal(false);

  constructor() {
    fromEvent(window, 'offline').subscribe(() => { this._isOffline.set(true) });
    fromEvent(window, 'online').subscribe(() => { this._isOffline.set(false) });
  }

  public get isOffline () {
    return this._isOffline.asReadonly();
  }

}
