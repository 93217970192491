import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Directive, inject, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { Nullable } from '../models/types';
import * as OperatorSelectors from '../../core/operators/store/operators.selector';

@UntilDestroy()
@Directive({
  selector: '[taIfLogged]'
})
export class IfLoggedDirective implements OnInit, OnChanges {
  private readonly template = inject(TemplateRef<any>);
  private readonly view = inject(ViewContainerRef);
  private readonly store = inject(Store);
  private isLogged = false;

  @Input('taIfLogged')
  public embedded: Nullable<boolean>;

  public ngOnInit (): void {
    this.store.select(OperatorSelectors.loggedOperatorId)
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(oId => {
        this.isLogged = oId !== 0 && oId != null;
        this.showHide();
      });
  }

  public ngOnChanges (): void {
    this.showHide();
  }

  private showHide (): void {
    if (this.isLogged && !this.embedded) {
      this.view.clear();
      // Visualizzo l'elemento
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }
}
