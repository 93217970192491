import { inject, Injectable, isDevMode } from "@angular/core";
import { Translation, TranslocoLoader } from "@jsverse/transloco";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
class TranslocoHttpLoader implements TranslocoLoader {
  private readonly http = inject(HttpClient);

  public getTranslation (lang: string): Observable<Translation> {
    return this.http.get<Translation>(`./assets/i18n/transloco/${lang}.json`);
  }
}

export default {
  config: {
    availableLangs: ['en', 'it', 'es'],
    defaultLang: 'it',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: !isDevMode(),
    fallbackLang: 'en'
  },
  loader: TranslocoHttpLoader
};
