import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit } from '@angular/core';
import { ActivatedRoute, createUrlTreeFromSnapshot, RouterOutlet } from '@angular/router';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { RouterService } from '../../../core/router/service/router.service';
import { NGXLogger } from 'ngx-logger';
import * as AssetDetailSelectors from '../store/asset-detail.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { AssetDetailApiActions } from '../store/asset-detail.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgrxStateGuidService } from '../../../core/services/gui/ngrx-state-guid.service';

@UntilDestroy()
@Component({
  selector: 'ta-asset-wrapper',
  template: `
    <ta-dialog [visible]="true" [showHeader]="!this.isLoading()" [showFooter]="false" bodyCssClass="tw-size-full" [dialogCssClass]="this.dialogCss()"
               [maximize]="!this.outletModal()" [transitionOptions]="this.dialogTransitionOptions()"
    >
      <ng-template #headerTemplate>
        <div class="tw-flex tw-flex-none tw-pt-4 tw-px-5 tw-pb-6">
          <div class="tw-grow tw-font-normal tw-truncate tw-text-2xl" [title]="this.headerTitle()">{{this.headerTitle()}}</div>
          @if (!this.windowOpen()) {
            <div class="tw-flex-none">
              <fa-icon class="tw-cursor-pointer" [icon]="this.faTimes" (click)="this.hideModal()"></fa-icon>
            </div>
          }
        </div>
      </ng-template>
      <ng-template #bodyTemplate>
        <div class="tw-flex-1 tw-overflow-hidden">
          <router-outlet></router-outlet>
        </div>
      </ng-template>
    </ta-dialog>
  `,
  imports: [
    RouterOutlet,
    DialogComponent,
    FaIconComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetDetailWrapperComponent implements OnInit {

  readonly #store = inject(Store);
  readonly #routerService = inject(RouterService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #logger = inject(NGXLogger);
  readonly #actions$ = inject(Actions);
  readonly #guidService = inject(NgrxStateGuidService);

  // Parametri provenienti dalla rotta
  public outletModal = input(false);
  public windowOpen = input(false);

  public dialogCss = computed(() => this.outletModal() ? 'tw-h-full 3xl:tw-h-240 tw-w-full lg:tw-w-360' : 'tw-size-full');
  public dialogTransitionOptions = computed(() => this.outletModal() ? '150ms cubic-bezier(0, 0, 0.2, 1)' : '0ms');
  public isLoading = this.#store.selectSignal(AssetDetailSelectors.isLoading(this.#guidService.stateId));
  public headerTitle = computed(() => {
    const device = this.#store.selectSignal(AssetDetailSelectors.asset(this.#guidService.stateId))();
    return device?.customerDescr + " - " + device?.code;
  });
  public faTimes = faTimes;

  public ngOnInit (): void {
    this.#actions$.pipe(
      ofType(AssetDetailApiActions.deleteSuccess),
      untilDestroyed(this)
    ).subscribe(() => {
      this.hideModal();
    });
  }

  public hideModal (): void {
    if(this.outletModal()) {
      const tree = createUrlTreeFromSnapshot(this.#activatedRoute.snapshot, []);
      if ('primary' in tree.root.children) {
        this.#routerService.directNavigate({
          path: [{ outlets: { modal: null, primary: tree.root.children['primary'].toString() } }],
          extras: { queryParamsHandling: 'merge', queryParams: { dId: null, cId: null } },
        });
      } else {
        this.#logger.error('No primary url available.');
      }
    } else if (this.windowOpen()) {
      window.close();
    }
  }

}
