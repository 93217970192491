import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Params, ResolveEnd, Router } from '@angular/router';
import { createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from './router.actions';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash-es';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import * as RouterSelectors from './router.selectors';
import { RouterService } from '../service/router.service';

@Injectable({ providedIn: 'any' })
export class RouterEffects extends NgRxEffectsBase {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly location = inject(Location);
  private readonly routerService = inject(RouterService);

  public goEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.go),
    withLatestFrom(this.store.select(RouterSelectors.embedded)),
    switchMap(([{ path, extras }, emb]) => this.activatedRoute.queryParamMap.pipe(
      take(1),
      map((params: ParamMap) => {
        let newextras: NavigationExtras = { ...extras };
        // Gestisco il parametro embedded
        let embedded = params.get('embedded');
        if (!embedded && emb) {
          embedded = `${emb}`;
        }
        if ((extras?.queryParams) != null) {
          newextras = cloneDeep(extras);
          newextras.queryParams = { ...extras.queryParams };
        }
        if (extras?.state?.['relative'] && (this.routerService.activatedRoute != null)) {
          newextras.relativeTo = this.routerService.activatedRoute;
        }
        if(extras?.state?.['forceReload']) {
          newextras.onSameUrlNavigation = 'reload';
        }
        if (embedded) {
          if (newextras.queryParams != null) {
            newextras.queryParams = { ...newextras.queryParams, embedded };
          } else {
            newextras.queryParams = { embedded };
          }
        }
        return { path, extras: newextras };
      }))
    ),
    tap(({ path, extras }) => {
      this.router.navigate(path, { ...extras });
    })
  ), { dispatch: false });

  public backEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.back),
    tap(() => {
      const history = this.routerService.history;
      if (history.length > 1) {
        history.pop();
        const { path, extras } = history[history.length - 1];
        this.store.dispatch(RouterActions.go({ path, extras: { ...extras, state: { relative: false } } }));
      }
    })
  ), { dispatch: false });

  public forwardEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.forward),
    tap(() => { this.location.forward(); })
  ), { dispatch: false });

  constructor () {
    super();
    this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .subscribe((e) => {
        const { path, queryParams } = this.resolveUrl((e as ResolveEnd).url);
        this.routerService.history.push({ path, extras: { queryParams } });
      });
  }

  private resolveUrl (url: string): {path: string[], queryParams: Params} {
    const queryParams = this.router.parseUrl(url).queryParams;
    const path = url.split('?')[0].split('/').filter(r => r);
    return { path, queryParams };
  }
}
